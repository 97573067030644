import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { KeywordSearchState } from '../../types/KeywordSearch/state';

const keywordSearchSlice = createSlice({
  name: 'keywordSearch',
  initialState: {
    isKeywordSearchModeOn: false,
    previousConversationId: '',
    selectedConversationId: '', // ID FROM DB
    isFetching: false,
  } as KeywordSearchState,
  reducers: {
    setKeywordSearchMode: (state, action: PayloadAction<boolean>) => {
      state.isKeywordSearchModeOn = action.payload;
    },
    setPreviousConversationId: (state, action: PayloadAction<string>) => {
      state.previousConversationId = action.payload;
    },
    setSelectedConversationId: (state, action: PayloadAction<string>) => {
      state.selectedConversationId = action.payload;
    },
    setIsFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
  },
});

export { keywordSearchSlice };
